.L8lE7q_error {
  color: red;
  background-color: #fff;
  margin: 1vh 0;
}

.L8lE7q_contentContainer {
  min-height: 80vh;
  background-image: url("tile@2x.9101e8c0.png");
  background-repeat: repeat-y;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 10vh;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (min-width: 320px) {
  .L8lE7q_contentContainer {
    width: 100vw;
    background-size: 100vw;
  }
}

@media (min-width: 481px) {
  .L8lE7q_contentContainer {
    width: 350px;
    background-size: 350px;
  }
}

.L8lE7q_contentContainer .L8lE7q_smallHeaderText {
  background-color: #fff;
  margin: 5vh 0 0;
  font-size: 1em;
}

.L8lE7q_contentContainer .L8lE7q_largeHeaderText {
  background-color: #fff;
  margin: 1vh 0;
  font-size: 2.33em;
}

.L8lE7q_contentContainer .L8lE7q_pulsingText {
  animation: 1.5s linear infinite L8lE7q_pulse;
}

.L8lE7q_contentContainer .L8lE7q_subText {
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
}

.L8lE7q_stretchedOvalButton {
  color: #000;
  text-align: center;
  width: 98%;
  background: none;
  border: thin solid #000;
  border-radius: 25px;
  margin: 1vh 0;
  padding: 15px 0;
  font-size: 1em;
  position: relative;
  left: 1%;
}

.L8lE7q_downloadLinkDiv {
  margin: 2vh 0 0;
}

.L8lE7q_downloadLinkDiv .L8lE7q_downloadLink {
  color: #333;
}

.L8lE7q_downloadLinkDiv .L8lE7q_downloadLink:hover {
  opacity: .5;
}

@keyframes L8lE7q_pulse {
  50% {
    opacity: 0;
  }
}

.L8lE7q_header {
  width: 100vw;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: relative;
}

@media (min-width: 320px) {
  .L8lE7q_header {
    width: 100vw;
    background-size: 100vw;
  }
}

@media (min-width: 481px) {
  .L8lE7q_header {
    width: 350px;
    background-size: 350px;
  }
}

@media (min-width: 320px) {
  .L8lE7q_header .L8lE7q_headerImage {
    width: 100vw;
    background-size: 100vw;
  }
}

@media (min-width: 481px) {
  .L8lE7q_header .L8lE7q_headerImage {
    width: 350px;
    background-size: 350px;
  }
}

.eijppW_error {
  color: red;
  background-color: #fff;
  margin: 1vh 0;
}

.eijppW_contentContainer {
  min-height: 80vh;
  background-image: url("tile@2x.9101e8c0.png");
  background-repeat: repeat-y;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 10vh;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (min-width: 320px) {
  .eijppW_contentContainer {
    width: 100vw;
    background-size: 100vw;
  }
}

@media (min-width: 481px) {
  .eijppW_contentContainer {
    width: 350px;
    background-size: 350px;
  }
}

.eijppW_contentContainer .eijppW_smallHeaderText {
  background-color: #fff;
  margin: 5vh 0 0;
  font-size: 1em;
}

.eijppW_contentContainer .eijppW_largeHeaderText {
  background-color: #fff;
  margin: 1vh 0;
  font-size: 2.33em;
}

.eijppW_contentContainer .eijppW_pulsingText {
  animation: 1.5s linear infinite eijppW_pulse;
}

.eijppW_contentContainer .eijppW_subText {
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
}

.eijppW_stretchedOvalButton {
  color: #000;
  text-align: center;
  width: 98%;
  background: none;
  border: thin solid #000;
  border-radius: 25px;
  margin: 1vh 0;
  padding: 15px 0;
  font-size: 1em;
  position: relative;
  left: 1%;
}

.eijppW_downloadLinkDiv {
  margin: 2vh 0 0;
}

.eijppW_downloadLinkDiv .eijppW_downloadLink {
  color: #333;
}

.eijppW_downloadLinkDiv .eijppW_downloadLink:hover {
  opacity: .5;
}

@keyframes eijppW_pulse {
  50% {
    opacity: 0;
  }
}

.eijppW_thumbnailContainer {
  width: calc(350px - 2vw);
  object-fit: contain;
  border-bottom: thin solid #000;
  border-left: thin solid #000;
  border-right: thin solid #000;
  margin: 0;
  padding: 0;
  position: relative;
}

.eijppW_thumbnailContainer .eijppW_ARIcon {
  width: 5%;
  background-color: #fff;
  padding: 2%;
  position: absolute;
  top: 5%;
  right: 5%;
}

.eijppW_tipText {
  padding: 1vh 2vw;
  font-style: italic;
  position: relative;
}

.eijppW_thumbnail {
  width: 100%;
}

.fkTqeG_error {
  color: red;
  background-color: #fff;
  margin: 1vh 0;
}

.fkTqeG_contentContainer {
  min-height: 80vh;
  background-image: url("tile@2x.9101e8c0.png");
  background-repeat: repeat-y;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 10vh;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (min-width: 320px) {
  .fkTqeG_contentContainer {
    width: 100vw;
    background-size: 100vw;
  }
}

@media (min-width: 481px) {
  .fkTqeG_contentContainer {
    width: 350px;
    background-size: 350px;
  }
}

.fkTqeG_contentContainer .fkTqeG_smallHeaderText {
  background-color: #fff;
  margin: 5vh 0 0;
  font-size: 1em;
}

.fkTqeG_contentContainer .fkTqeG_largeHeaderText {
  background-color: #fff;
  margin: 1vh 0;
  font-size: 2.33em;
}

.fkTqeG_contentContainer .fkTqeG_pulsingText {
  animation: 1.5s linear infinite fkTqeG_pulse;
}

.fkTqeG_contentContainer .fkTqeG_subText {
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
}

.fkTqeG_stretchedOvalButton {
  color: #000;
  text-align: center;
  width: 98%;
  background: none;
  border: thin solid #000;
  border-radius: 25px;
  margin: 1vh 0;
  padding: 15px 0;
  font-size: 1em;
  position: relative;
  left: 1%;
}

.fkTqeG_downloadLinkDiv {
  margin: 2vh 0 0;
}

.fkTqeG_downloadLinkDiv .fkTqeG_downloadLink {
  color: #333;
}

.fkTqeG_downloadLinkDiv .fkTqeG_downloadLink:hover {
  opacity: .5;
}

@keyframes fkTqeG_pulse {
  50% {
    opacity: 0;
  }
}

.fkTqeG_videoPlayer {
  width: calc(350px - 2vw);
  object-fit: contain;
  border-bottom: thin solid #000;
  border-left: thin solid #000;
  border-right: thin solid #000;
  margin: 0;
  padding: 0;
  position: relative;
}

.OGmkqa_error {
  color: red;
  background-color: #fff;
  margin: 1vh 0;
}

.OGmkqa_contentContainer {
  min-height: 80vh;
  background-image: url("tile@2x.9101e8c0.png");
  background-repeat: repeat-y;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 10vh;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (min-width: 320px) {
  .OGmkqa_contentContainer {
    width: 100vw;
    background-size: 100vw;
  }
}

@media (min-width: 481px) {
  .OGmkqa_contentContainer {
    width: 350px;
    background-size: 350px;
  }
}

.OGmkqa_contentContainer .OGmkqa_smallHeaderText {
  background-color: #fff;
  margin: 5vh 0 0;
  font-size: 1em;
}

.OGmkqa_contentContainer .OGmkqa_largeHeaderText {
  background-color: #fff;
  margin: 1vh 0;
  font-size: 2.33em;
}

.OGmkqa_contentContainer .OGmkqa_pulsingText {
  animation: 1.5s linear infinite OGmkqa_pulse;
}

.OGmkqa_contentContainer .OGmkqa_subText {
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
}

.OGmkqa_stretchedOvalButton {
  color: #000;
  text-align: center;
  width: 98%;
  background: none;
  border: thin solid #000;
  border-radius: 25px;
  margin: 1vh 0;
  padding: 15px 0;
  font-size: 1em;
  position: relative;
  left: 1%;
}

.OGmkqa_downloadLinkDiv {
  margin: 2vh 0 0;
}

.OGmkqa_downloadLinkDiv .OGmkqa_downloadLink {
  color: #333;
}

.OGmkqa_downloadLinkDiv .OGmkqa_downloadLink:hover {
  opacity: .5;
}

@keyframes OGmkqa_pulse {
  50% {
    opacity: 0;
  }
}

.OGmkqa_assets {
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
  font-family: Helvetica;
  font-size: 14px;
  display: flex;
}

.OGmkqa_assets .OGmkqa_contentContainer .OGmkqa_assetsImage {
  width: calc(350px - 2vw);
  object-fit: contain;
  border-bottom: thin solid #000;
  border-left: thin solid #000;
  border-right: thin solid #000;
  margin: 0;
  padding: 0;
  position: relative;
}

.OGmkqa_assets .OGmkqa_contentContainer .OGmkqa_assetsImage > img {
  width: 100%;
  height: 100%;
}

.Fm3JSa_error {
  color: red;
  background-color: #fff;
  margin: 1vh 0;
}

.Fm3JSa_contentContainer {
  min-height: 80vh;
  background-image: url("tile@2x.9101e8c0.png");
  background-repeat: repeat-y;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 10vh;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (min-width: 320px) {
  .Fm3JSa_contentContainer {
    width: 100vw;
    background-size: 100vw;
  }
}

@media (min-width: 481px) {
  .Fm3JSa_contentContainer {
    width: 350px;
    background-size: 350px;
  }
}

.Fm3JSa_contentContainer .Fm3JSa_smallHeaderText {
  background-color: #fff;
  margin: 5vh 0 0;
  font-size: 1em;
}

.Fm3JSa_contentContainer .Fm3JSa_largeHeaderText {
  background-color: #fff;
  margin: 1vh 0;
  font-size: 2.33em;
}

.Fm3JSa_contentContainer .Fm3JSa_pulsingText {
  animation: 1.5s linear infinite Fm3JSa_pulse;
}

.Fm3JSa_contentContainer .Fm3JSa_subText {
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
}

.Fm3JSa_stretchedOvalButton {
  color: #000;
  text-align: center;
  width: 98%;
  background: none;
  border: thin solid #000;
  border-radius: 25px;
  margin: 1vh 0;
  padding: 15px 0;
  font-size: 1em;
  position: relative;
  left: 1%;
}

.Fm3JSa_downloadLinkDiv {
  margin: 2vh 0 0;
}

.Fm3JSa_downloadLinkDiv .Fm3JSa_downloadLink {
  color: #333;
}

.Fm3JSa_downloadLinkDiv .Fm3JSa_downloadLink:hover {
  opacity: .5;
}

@keyframes Fm3JSa_pulse {
  50% {
    opacity: 0;
  }
}

.Fm3JSa_home {
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
  font-family: Helvetica;
  font-size: 14px;
  display: flex;
}

.Fm3JSa_home .Fm3JSa_contentContainer {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Fm3JSa_swoosh {
  width: 350px;
  object-fit: contain;
}

.Fm3JSa_swoosh > img {
  width: 100%;
}

.jPWDZa_error {
  color: red;
  background-color: #fff;
  margin: 1vh 0;
}

.jPWDZa_contentContainer {
  min-height: 80vh;
  background-image: url("tile@2x.9101e8c0.png");
  background-repeat: repeat-y;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 10vh;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (min-width: 320px) {
  .jPWDZa_contentContainer {
    width: 100vw;
    background-size: 100vw;
  }
}

@media (min-width: 481px) {
  .jPWDZa_contentContainer {
    width: 350px;
    background-size: 350px;
  }
}

.jPWDZa_contentContainer .jPWDZa_smallHeaderText {
  background-color: #fff;
  margin: 5vh 0 0;
  font-size: 1em;
}

.jPWDZa_contentContainer .jPWDZa_largeHeaderText {
  background-color: #fff;
  margin: 1vh 0;
  font-size: 2.33em;
}

.jPWDZa_contentContainer .jPWDZa_pulsingText {
  animation: 1.5s linear infinite jPWDZa_pulse;
}

.jPWDZa_contentContainer .jPWDZa_subText {
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
}

.jPWDZa_stretchedOvalButton {
  color: #000;
  text-align: center;
  width: 98%;
  background: none;
  border: thin solid #000;
  border-radius: 25px;
  margin: 1vh 0;
  padding: 15px 0;
  font-size: 1em;
  position: relative;
  left: 1%;
}

.jPWDZa_downloadLinkDiv {
  margin: 2vh 0 0;
}

.jPWDZa_downloadLinkDiv .jPWDZa_downloadLink {
  color: #333;
}

.jPWDZa_downloadLinkDiv .jPWDZa_downloadLink:hover {
  opacity: .5;
}

@keyframes jPWDZa_pulse {
  50% {
    opacity: 0;
  }
}

.jPWDZa_errorPage {
  text-align: center;
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
  font-family: Helvetica;
  font-size: 14px;
  display: flex;
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

/*# sourceMappingURL=index.cbc7a2d9.css.map */
