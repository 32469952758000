$masterWidth: 350px;


@mixin responsiveSizing {
    @media (min-width:320px)  { 
        width: 100vw;
        background-size: 100vw;
    }
    @media (min-width:481px)  { 
        width: $masterWidth;
        background-size: $masterWidth
    }
}

@mixin pageStyles {
    font-size: 14px;
    font-family: 'Helvetica';
    text-align: center;
    margin: 0; 
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

@mixin mediaContainer {
    position: relative;
    width: calc($masterWidth - 2vw);
    object-fit: contain;
    border-left: thin black solid;
    border-right: thin black solid;
    border-bottom: thin black solid;
    padding: 0;
    margin: 0;
}

@mixin ovalButton {
    position: relative;
    background: transparent;
    border: thin black solid;
    color: black;
    border-radius: 25px;
    font-size: 1em;
    text-align: center;
}

.error {
    color: red;
    margin: 1vh 0;    
    background-color: white;
}


.contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;    
    @include responsiveSizing;
    overflow: hidden;
    min-height: 80vh;
    background-image: url('/assets/images/tile@2x.png');
    background-repeat: repeat-y; 
    padding: 0 0 10vh 0;


    .smallHeaderText {   
        margin: 5vh 0 0 0;
        font-size: 1em;      
        background-color: white;
    }

    .largeHeaderText {     
        font-size: 2.33em;
        margin: 1vh 0;
        background-color: white;
    }

    .pulsingText {
        animation: pulse 1.5s linear infinite;
    }

    .subText {
        display: inline-block;
        font-size: 16px;
        line-height: 24px;
    }
}

.stretchedOvalButton {
    @include ovalButton;
    width: 98%;
    left: 1%;
    padding: 15px 0;
    margin: 1vh 0;
}

.downloadLinkDiv {
    margin: 2vh 0 0 0;
    .downloadLink {
        color: #333333;
        &:hover {
            opacity: 0.5;
        }
    }
}

@keyframes pulse {
    50% {
      opacity: 0;
    }
  }